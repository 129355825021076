import {
  Box,
  chipClasses,
  Container,
  styled,
  Table,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography
} from '@mui/material';
import { ReactComponent as TitleUnderlineSvg } from './title-underline.svg';

export const ReportContainer = styled(Box)(({ theme }) => ({
  fontFamily: 'avenir-lt-w01_85-heavy1475544,sans-serif',
  '& a': {
    textDecoration: 'none'
  },
  '.MuiTabs-root button': {
    color: theme.palette.common.black
  },

  '.MuiTabs-root .Mui-selected': {
    color: '#00ccff'
  },
  '.MuiTabs-root .MuiTabs-indicator': {
    backgroundColor: '#00ccff'
  }
}));

export const Title = styled(Typography)(() => ({
  fontFamily: '"League_Spartan variant0", Tofu, sans-serif'
}));

export const Section = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2)
}));

export const SectionContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  maxWidth: theme.breakpoints.values.lg
}));

export const StyledTable = styled(Table)(() => ({
  pageBreakInside: 'auto'
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: 'center',
    '@media print': {
      padding: 2,
      fontSize: 12
    }
  },
  [`&.${tableCellClasses.root}`]: {
    padding: '16px 10px'
  },
  [`&.${tableCellClasses.body}`]: {
    textAlign: 'center',
    padding: '4px 3px',
    '@media print': {
      padding: '4px 2px',
      fontSize: 10
    }
  },
  [`> .${chipClasses.root}`]: {
    background: 'transparent',
    [`& .${chipClasses.label}`]: {
      minWidth: '80px'
    }
  }
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  pageBreakInside: 'avoid',
  pageBreakAfter: 'auto',
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  },
  '&.highlight': {
    backgroundColor: '#00ccff'
  },
  '&:nth(6)': {
    pageBreakAfter: 'always'
  }
}));

export const TitleUnderline = styled(TitleUnderlineSvg)`
  width: 184px;
  height: 18px;
  transform: rotate(4.27273552905325deg);
`;

export const PageBreakBefore = {
  pageBreakBefore: 'always'
};

export const NoPageBreakBefore = {
  pageBreakBefore: 'avoid'
};
